import React from 'react'
import { useTranslation } from 'react-i18next'

import { effectiveFulfillmentMethod } from '@src/components/SingleOutlet/utils/effectiveFulfilmentMethod'
import { TextClamp } from '@src/components/Text/Clamp'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import {
  MenuItemCounter,
  MenuItemCounterText,
} from '@src/pages/OutletPage/OutletMenu/MenuItem/MenuItem.styles'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  Cuisine,
  CuisineDot,
  CuisineTag,
  CusinesContainer,
  Description,
  DiscountText,
  DiscountTextContainer,
  LinkToOutletContainer,
  LongSquareButton,
  MinimumOrderContainer,
  OfferContainer,
  OfferName,
  OpenStatusContainer,
  OutletDetailsContainer,
  OutletName,
  OutletNameContainer,
  OutletOpeningContainer,
  RestaurantContainer,
  RestaurantLogo,
  StyledDiscountSVG,
} from './OutletCard.styles'
import {
  LoyaltyCardStampAndIconContainer,
  LoyaltyCardImage,
  ImageContainer,
  LoyaltyCardTextContainer,
  DiscountsAndLoyaltyCardsContainer,
} from './SingleOutlet.styles'
import { SingleOutlet, SingleOutletDirection } from './types'

// We have to type this until we change to pass in just outlet id and we fetch what we need ourselves.
import { LoyaltyCardStampProgressMeter } from '../CustomerLoyaltyCard/StampProgressMeter'
import { OutletStatusTranslationText } from '../OutletStatus/OutletStatusTranslationText'

export const OutletCard: React.FC<{
  outlet: DateifyOutlet<
    Pick<
      SingleOutlet,
      | 'id'
      | 'restaurant'
      | 'availableFulfilmentInputMethods'
      | 'description'
      | 'deliveryMinimumOrderValue'
      | 'collectionMinimumOrderValue'
      | 'isOnline'
      | 'isOpen'
      | 'distanceFromUserKM'
      | 'ASAPDeliveryDuration'
      | 'prepTime'
      | 'statusText'
      | 'nextOpenDate'
      | 'outletLogoOverride'
      | 'availableFulfillmentMethods'
      | 'displayName'
      | 'fulfilmentRange'
      | 'outletAllDiscounts'
      | 'specialOfferFlags'
      | 'outletCuisines'
      | 'outletLoyaltyCards'
    >
  >
  direction?: SingleOutletDirection
  className?: string
  isSlide?: boolean
  dataTestId?: string
}> = ({
  outlet,
  direction = SingleOutletDirection.COLUMN,
  className,
  isSlide = false,
  dataTestId,
}) => {
  const { t } = useTranslation(['outlet', 'outletCard', 'customerLoyaltyCard'])
  const basketItems = useBasketItems()
  const {
    data: { priorityFulfilmentMethod },
  } = useFulfilmentFilter()

  const effectiveFulfilmentMethod = effectiveFulfillmentMethod(
    outlet.availableFulfilmentInputMethods,
    priorityFulfilmentMethod
  )

  const outletDiscounts = outlet.outletAllDiscounts ?? []
  const formatCurrency = useFormatCurrency(false)

  const outletWithBasketItems = basketItems.items.some(
    basket => basket.outletMenuItemId.split(':')[0] === outlet.id
  )

  const basketItemCount =
    outletWithBasketItems &&
    basketItems.items.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

  const fulfilmentToDealMap: Record<
    NarrowFulfilmentMethodInputType,
    string | undefined | null
  > = {
    [NarrowFulfilmentMethodInputType.COLLECTION]:
      outlet.specialOfferFlags?.collectionDeal,
    [NarrowFulfilmentMethodInputType.DELIVERY]:
      outlet.specialOfferFlags?.deliveryDeal,
    [NarrowFulfilmentMethodInputType.TABLE]:
      outlet.specialOfferFlags?.tableDeal,
  }

  let deal = fulfilmentToDealMap[effectiveFulfilmentMethod]

  const isDeliveryAvailable = outlet.availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.DELIVERY
  )
  const isCollectionAvailable = outlet.availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.COLLECTION
  )
  if (
    !isDeliveryAvailable &&
    isCollectionAvailable &&
    deal?.includes('delivery')
  ) {
    deal = null
  }

  const loyaltyCardsWithStamps = outlet.outletLoyaltyCards.filter(
    card => card.loyaltyCardStamps && card.loyaltyCardStamps.length > 0
  )

  const loyaltyCardText = () => {
    if (
      outlet.outletLoyaltyCards.length === 1 &&
      loyaltyCardsWithStamps.length === 0
    ) {
      return `${outlet.outletLoyaltyCards[0]?.discount.name}`
    } else if (outlet.outletLoyaltyCards.length > 1) {
      return outlet.outletAllDiscounts.length <= 1
        ? `${t('loyalty_cards_available', {
            numOfCards: outlet.outletLoyaltyCards.length,
            ns: 'customerLoyaltyCard',
          })}`
        : `${t('num_of_cards', {
            numOfCards: outlet.outletLoyaltyCards.length,
            ns: 'customerLoyaltyCard',
          })}`
    } else return ''
  }

  return (
    <LinkToOutletContainer
      outletId={outlet.id}
      name={outlet.displayName}
      className={className}
      status={outlet.statusText.orderButtonTranslation}
      dataTestId={dataTestId}
    >
      <OfferContainer visible={Boolean(deal)}>
        <OfferName>
          <TextClamp clamp={2}>{deal}</TextClamp>
        </OfferName>
      </OfferContainer>

      <RestaurantContainer
        hasOffer={isSlide || !!deal}
        direction={direction}
        isSlide={isSlide}
      >
        <RestaurantLogo
          role="img"
          aria-label={`${outlet.displayName} logo`}
          imageUrl={imageJitURL(
            outlet.outletLogoOverride || outlet.restaurant.image,
            {
              resize: {
                width: 88,
                height: 88,
                fit: 'cover',
              },
            }
          )}
          isSlide={isSlide}
        >
          {outletWithBasketItems && basketItemCount && (
            <MenuItemCounter isMobile={true}>
              <MenuItemCounterText>{basketItemCount}</MenuItemCounterText>
            </MenuItemCounter>
          )}
        </RestaurantLogo>
        <OutletDetailsContainer isSlide={isSlide}>
          <OutletNameContainer>
            <TextClamp tooltipText={outlet.displayName}>
              <OutletName>{outlet.displayName}</OutletName>
            </TextClamp>
          </OutletNameContainer>

          <CusinesContainer>
            {outlet.outletCuisines
              .slice(0, 3)
              .map((cuisine: { name: string }, index: number) => {
                return (
                  <CuisineTag key={index}>
                    {index !== 0 ? <CuisineDot /> : null}

                    <Cuisine>{cuisine.name}</Cuisine>
                  </CuisineTag>
                )
              })}
          </CusinesContainer>

          {outlet.description && (
            <Description>
              <TextClamp clamp={2} tooltipText={outlet.description}>
                <span>{outlet.description}</span>
              </TextClamp>
            </Description>
          )}
          <DiscountsAndLoyaltyCardsContainer>
            {effectiveFulfilmentMethod !==
              NarrowFulfilmentMethodInputType.TABLE &&
              outletDiscounts.length > 0 && (
                <DiscountTextContainer>
                  {outletDiscounts.length > 1 ? (
                    <DiscountText>
                      <StyledDiscountSVG id="svg-x-discounts" />
                      {outlet.outletLoyaltyCards.length <= 1
                        ? t('x_offers_available', {
                            discountCount: outletDiscounts.length,
                            ns: 'outletCard',
                          })
                        : t('num_of_offers', {
                            discountCount: outletDiscounts.length,
                            ns: 'outletCard',
                          })}
                    </DiscountText>
                  ) : (
                    outletDiscounts?.map(discount => (
                      <DiscountText key={discount.id}>
                        <StyledDiscountSVG id={discount.id} />
                        <TextClamp tooltipText={discount.name}>
                          {discount.name}
                        </TextClamp>
                      </DiscountText>
                    ))
                  )}
                </DiscountTextContainer>
              )}
            {outlet.outletLoyaltyCards.length > 0 ? (
              <LoyaltyCardStampAndIconContainer>
                <ImageContainer imageCount={outlet.outletLoyaltyCards.length}>
                  {outlet.outletLoyaltyCards.map((loyaltyCard, index) => (
                    <LoyaltyCardImage
                      key={loyaltyCard.id}
                      index={index}
                      imageCount={outlet.outletLoyaltyCards.length}
                      role="img"
                      aria-label={`${outlet.displayName} loyalty card`}
                      imageUrl={imageJitURL(loyaltyCard.loyaltyCardIcon, {
                        resize: {
                          width: 168,
                          height: 168,
                          fit: 'cover',
                        },
                      })}
                    />
                  ))}
                </ImageContainer>

                {loyaltyCardsWithStamps.length === 1 &&
                  outlet.outletLoyaltyCards.length === 1 && (
                    <LoyaltyCardStampProgressMeter
                      loyaltyCard={outlet.outletLoyaltyCards[0]}
                      isOutletList={true}
                    />
                  )}

                <LoyaltyCardTextContainer
                  textColor={
                    outlet.outletLoyaltyCards.length > 0
                      ? outlet.outletLoyaltyCards[
                          outlet.outletLoyaltyCards.length - 1
                        ]?.loyaltyCardColor || 'black'
                      : 'black'
                  }
                >
                  {loyaltyCardText()}
                </LoyaltyCardTextContainer>
              </LoyaltyCardStampAndIconContainer>
            ) : null}
          </DiscountsAndLoyaltyCardsContainer>

          <OutletOpeningContainer>
            <OpenStatusContainer>
              <MinimumOrderContainer>
                {effectiveFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.DELIVERY &&
                outlet.deliveryMinimumOrderValue ? (
                  <>
                    {formatCurrency(outlet.deliveryMinimumOrderValue)}{' '}
                    {t('minimum_order', { ns: 'outletCard' })}
                  </>
                ) : null}
                {effectiveFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.COLLECTION &&
                outlet.collectionMinimumOrderValue ? (
                  <>
                    {formatCurrency(outlet.collectionMinimumOrderValue)}{' '}
                    {t('minimum_order', { ns: 'outletCard' })}
                  </>
                ) : null}
              </MinimumOrderContainer>
              <OutletStatusTranslationText
                statusText={outlet.statusText}
                fulfilmentRange={outlet.fulfilmentRange}
                isOutletPage={false}
              />
            </OpenStatusContainer>
            <LongSquareButton
              status={outlet.statusText.orderButtonTranslation}
              content={t(outlet.statusText.orderButtonTranslation, {
                ns: 'outlet',
              })}
            />
          </OutletOpeningContainer>
        </OutletDetailsContainer>
      </RestaurantContainer>
    </LinkToOutletContainer>
  )
}
