import React from 'react'
import { useTranslation } from 'react-i18next'

import { DetailedPaymentMethod } from '@src/graphql-types'
import { CustomerDetailsAndAddresses_customerDetails_loyaltyCards } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/queries/__generated__/CustomerDetailsAndAddresses'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { UseBasketTotalsResponseData } from '@src/hooks/useTotals/useBasketTotals'

import {
  LoyaltyCardText,
  LoyaltyCardAmountSaved,
} from './CustomerLoyaltyCard.styles'

import { useFormatCurrency } from '../../hooks/useFormatCurrency'

export const LoyaltyCardInfoText: React.FC<{
  loyaltyCard: CustomerDetailsAndAddresses_customerDetails_loyaltyCards | null
  isTermsPage?: boolean
  basketTotals?: UseBasketTotalsResponseData
  amountToSpendToEarnStamp: string
  paymentMethod?: DetailedPaymentMethod
}> = ({
  loyaltyCard,
  isTermsPage = false,
  basketTotals,
  amountToSpendToEarnStamp,
  paymentMethod,
}) => {
  const { t } = useTranslation('customerLoyaltyCard')
  const checkout = useCheckoutRouter()
  const marketplace = useMarketplace()
  const formatCurrency = useFormatCurrency()

  if (!loyaltyCard) {
    return null
  }

  const { discount } = loyaltyCard
  const loyaltyCardStamps = loyaltyCard.loyaltyCardStamps || []
  const isLoyaltyCardFull =
    loyaltyCard.requiredStamps === loyaltyCardStamps.length

  const calculatePotentialToEarnOnOrder = () => {
    if (!basketTotals?.checkoutBasketTotals) {
      return formatCurrency(0)
    }

    // Calculate the basket item total without fees and applied discounts
    const basketItemTotalWithoutFees =
      loyaltyCard.discountStrategy === 'WHOLE_ORDER_DISCOUNT'
        ? basketTotals.checkoutBasketTotals.sumTotal
        : basketTotals.basketItemsTotal -
          (basketTotals.checkoutBasketTotals.appliedDiscount?.reductionAmount ||
            0)

    // If the total without fees is less than or equal to 0 and there is a discount percentage, return 0
    if (
      basketItemTotalWithoutFees <= 0 &&
      discount?.discountPercentage &&
      discount.discountPercentage !== 0
    ) {
      return formatCurrency(0)
    }

    let potentialSaving = 0

    // Calculate potential saving based on discount amount or percentage
    if (discount?.discountAmount && discount.discountAmount !== 0) {
      potentialSaving = discount.discountAmount
    } else if (
      discount?.discountPercentage &&
      discount.discountPercentage !== 0
    ) {
      potentialSaving =
        basketItemTotalWithoutFees * (discount.discountPercentage / 100)
    }
    return formatCurrency(potentialSaving) || formatCurrency(0)
  }

  const amountSavedFromStampTotals = loyaltyCard.netTotal
    ? formatCurrency(loyaltyCard.netTotal)
    : formatCurrency(0)

  const potentialToEarnOnOrder = calculatePotentialToEarnOnOrder()

  const genericLoyaltyCardText = () => {
    if (loyaltyCard.canStampBeEarnedToday === false) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('unavailable_today')}
        </LoyaltyCardText>
      )
    }
    if (
      amountSavedFromStampTotals !== formatCurrency(0) &&
      !isLoyaltyCardFull
    ) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('you_have_collected')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('so_far')}
        </LoyaltyCardText>
      )
    }

    if (isLoyaltyCardFull) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('ready_to_collect')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('reward')}
        </LoyaltyCardText>
      )
    }

    return (
      <LoyaltyCardText isTermsPage={isTermsPage}>
        {t('start_collecting_today')}
      </LoyaltyCardText>
    )
  }

  const termsAndConditionsLoyaltyText = () => {
    if (!loyaltyCard.canStampBeEarnedToday) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('card_unavailable_today')}
        </LoyaltyCardText>
      )
    }
    if (
      amountSavedFromStampTotals !== formatCurrency(0) &&
      !isLoyaltyCardFull
    ) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('you_have_collected')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('so_far')}
        </LoyaltyCardText>
      )
    }

    if (isLoyaltyCardFull) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('ready_to_collect')} {t('reward')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('will_be_discounted')}
        </LoyaltyCardText>
      )
    }

    return (
      <LoyaltyCardText isTermsPage={isTermsPage}>
        {t('start_collecting_today_extra_info', {
          businessOrRestaurant:
            marketplace.urlPath === 'takeaways' ? 'restaurants' : 'businesses',
        })}
      </LoyaltyCardText>
    )
  }

  const basketSummaryText = () => {
    if (amountToSpendToEarnStamp !== formatCurrency(0)) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('minimum_spend_error', {
            amountToSpendToEarnStamp: amountToSpendToEarnStamp,
          })}
        </LoyaltyCardText>
      )
    }
    if (!isLoyaltyCardFull && loyaltyCardStamps.length !== 0) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('earn')}{' '}
          <LoyaltyCardAmountSaved>
            {t('potential_to_earn', {
              potentialToEarn: potentialToEarnOnOrder,
            })}
          </LoyaltyCardAmountSaved>{' '}
          {t('on_next_stamp')}
        </LoyaltyCardText>
      )
    }

    if (isLoyaltyCardFull) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('ready_to_collect')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('reward')}
        </LoyaltyCardText>
      )
    }

    if (
      !loyaltyCardStamps.length &&
      potentialToEarnOnOrder > formatCurrency(0)
    ) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('earn')}{' '}
          <LoyaltyCardAmountSaved>
            {t('potential_to_earn', {
              potentialToEarn: potentialToEarnOnOrder,
            })}
          </LoyaltyCardAmountSaved>{' '}
          {t('on_first_stamp')}
        </LoyaltyCardText>
      )
    }

    return (
      <LoyaltyCardText isTermsPage={isTermsPage}>
        {t('start_collecting_today')}
      </LoyaltyCardText>
    )
  }

  const checkoutText = () => {
    if (
      !loyaltyCard.allowCashOrders &&
      paymentMethod === DetailedPaymentMethod.CASH
    ) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('cash_orders_not_accepted')}
        </LoyaltyCardText>
      )
    }
    if (!isLoyaltyCardFull) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('you_are_earning')}{' '}
          <LoyaltyCardAmountSaved>
            {t('potential_to_earn', {
              potentialToEarn: potentialToEarnOnOrder,
            })}
          </LoyaltyCardAmountSaved>{' '}
          {t('on_next_stamp')}
        </LoyaltyCardText>
      )
    }

    if (isLoyaltyCardFull) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('ready_to_collect')}{' '}
          <LoyaltyCardAmountSaved>
            {t('amount_saved', { amountSaved: amountSavedFromStampTotals })}
          </LoyaltyCardAmountSaved>{' '}
          {t('reward')}
        </LoyaltyCardText>
      )
    }

    if (!loyaltyCardStamps.length) {
      return (
        <LoyaltyCardText isTermsPage={isTermsPage}>
          {t('you_are_earning')}{' '}
          <LoyaltyCardAmountSaved>
            {t('potential_to_earn', {
              potentialToEarn: potentialToEarnOnOrder,
            })}
          </LoyaltyCardAmountSaved>{' '}
          {t('on_first_stamp')}
        </LoyaltyCardText>
      )
    }

    return (
      <LoyaltyCardText isTermsPage={isTermsPage}>
        {t('start_collecting_today')}
      </LoyaltyCardText>
    )
  }

  if (basketTotals) {
    if (
      checkout.route === CheckoutRoute.BASKET ||
      checkout.route === undefined
    ) {
      return basketSummaryText()
    } else {
      return checkoutText()
    }
  }

  if (isTermsPage) {
    return termsAndConditionsLoyaltyText()
  }

  return genericLoyaltyCardText()
}
