import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { effectiveFulfillmentMethod } from '@src/components/SingleOutlet/utils/effectiveFulfilmentMethod'
import { TextClamp } from '@src/components/Text/Clamp'
import { breakpoints } from '@src/constants/breakpoints'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { screenResolutionVar } from '@src/models/screenResolution'
import {
  MenuItemCounter,
  MenuItemCounterText,
} from '@src/pages/OutletPage/OutletMenu/MenuItem/MenuItem.styles'
import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  CoverImage,
  Cuisine,
  CuisineDot,
  CuisineTag,
  CusinesContainer,
  DiscountContainer,
  DiscountName,
  DiscountsContainer,
  InfoContainer,
  LinkToOutletContainer,
  LongSquareButton,
  MinimumOrderContainer,
  OfferContainer,
  OfferText,
  OpenStatusContainer,
  LoyaltyCardStampAndIconContainer,
  LoyaltyCardImage,
  OutletDetailsContainer,
  OutletName,
  OutletOpeningContainer,
  RestaurantContainer,
  RestaurantLogo,
  StyledDiscountSVG,
  ImageContainer,
  LoyaltyCardTextContainer,
} from './SingleOutlet.styles'
import {
  SingleOutletDirection,
  SingleOutlet as SingleOutletType,
} from './types'

import { LoyaltyCardStampProgressMeter } from '../CustomerLoyaltyCard/StampProgressMeter'
import { OutletStatusTranslationText } from '../OutletStatus/OutletStatusTranslationText'
import { ScallopedMaskSVG } from '../SVGS/ScallopedMaskSVG'

export const SingleOutlet: React.FC<{
  outlet: DateifyOutlet<
    Pick<
      SingleOutletType,
      | 'id'
      | 'displayName'
      | 'ASAPDeliveryDuration'
      | 'prepTime'
      | 'restaurant'
      | 'availableFulfilmentInputMethods'
      | 'deliveryMinimumOrderValue'
      | 'collectionMinimumOrderValue'
      | 'distanceFromUserKM'
      | 'coverImage'
      | 'statusText'
      | 'isOpen'
      | 'outletLogoOverride'
      | 'isOnline'
      | 'nextOpenDate'
      | 'openingTimesArray'
      | 'collectionPreorderTimes'
      | 'fulfilmentRange'
      | 'outletAllDiscounts'
      | 'specialOfferFlags'
      | 'outletCuisines'
      | 'outletLoyaltyCards'
      | 'availableFulfilments'
    >
  >
  direction?: SingleOutletDirection
  isSlide?: boolean
  showFeaturedFlags?: boolean
  showDiscountLabels?: boolean
  dataTestId?: string
}> = ({
  outlet,
  direction = SingleOutletDirection.COLUMN,
  isSlide = false,
  showFeaturedFlags = true,
  showDiscountLabels = true,
  dataTestId,
}) => {
  const screenResolution = useReactiveVar(screenResolutionVar)
  const basketItems = useBasketItems()
  const { t } = useTranslation([
    'discounts',
    'outletCard',
    'outlet',
    'customerLoyaltyCard',
  ])
  const formatCurrency = useFormatCurrency(false)
  const {
    data: { priorityFulfilmentMethod },
  } = useFulfilmentFilter()

  const effectiveFulfilmentMethod = effectiveFulfillmentMethod(
    outlet.availableFulfilmentInputMethods,
    priorityFulfilmentMethod
  )

  const fulfilmentToDealMap: Record<
    NarrowFulfilmentMethodInputType,
    string | undefined | null
  > = {
    [NarrowFulfilmentMethodInputType.COLLECTION]:
      outlet.specialOfferFlags?.collectionDeal,
    [NarrowFulfilmentMethodInputType.DELIVERY]:
      outlet.specialOfferFlags?.deliveryDeal,
    [NarrowFulfilmentMethodInputType.TABLE]:
      outlet.specialOfferFlags?.tableDeal,
  }
  let deal = fulfilmentToDealMap[effectiveFulfilmentMethod]

  const isDeliveryAvailable = outlet.availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.DELIVERY
  )
  const isCollectionAvailable = outlet.availableFulfilmentInputMethods.includes(
    NarrowFulfilmentMethodInputType.COLLECTION
  )
  if (
    !isDeliveryAvailable &&
    isCollectionAvailable &&
    deal?.includes('delivery')
  ) {
    deal = null
  }

  const showCoverImage = isSlide || screenResolution.width >= breakpoints.tablet

  const outletWithBasketItems = basketItems.items.some(
    basket => basket.outletMenuItemId.split(':')[0] === outlet.id
  )

  const basketItemCount =
    outletWithBasketItems &&
    basketItems.items.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

  const loyaltyCardsWithStamps = outlet.outletLoyaltyCards.filter(
    card => card.loyaltyCardStamps && card.loyaltyCardStamps.length > 0
  )

  const loyaltyCardText = () => {
    if (
      outlet.outletLoyaltyCards.length === 1 &&
      loyaltyCardsWithStamps.length === 0
    ) {
      return `${outlet.outletLoyaltyCards[0]?.discount.name}`
    } else if (outlet.outletLoyaltyCards.length > 1) {
      return `${t('loyalty_cards_available', {
        numOfCards: outlet.outletLoyaltyCards.length,
        ns: 'customerLoyaltyCard',
      })}`
    } else return ''
  }

  return (
    <LinkToOutletContainer
      outletId={outlet.id}
      name={outlet.displayName}
      status={outlet.statusText.orderButtonTranslation}
      dataTestId={dataTestId}
    >
      {showFeaturedFlags && deal && (
        <OfferContainer>
          <OfferText>
            <TextClamp clamp={3}>{deal}</TextClamp>
          </OfferText>
        </OfferContainer>
      )}
      {effectiveFulfilmentMethod !== NarrowFulfilmentMethodInputType.TABLE && (
        <>
          {showDiscountLabels && outlet.outletAllDiscounts && (
            <DiscountsContainer>
              {outlet.outletAllDiscounts?.length > 1 ? (
                <DiscountContainer>
                  <DiscountName>
                    <StyledDiscountSVG id={'many-discount-svg'} />
                    <TextClamp>
                      {t('x_offers_available', {
                        discountCount: outlet.outletAllDiscounts.length,
                        ns: 'discounts',
                      })}
                    </TextClamp>
                  </DiscountName>
                </DiscountContainer>
              ) : (
                outlet.outletAllDiscounts.map(discount => (
                  <DiscountContainer key={discount.id}>
                    <DiscountName>
                      <StyledDiscountSVG id={discount.id} />
                      <TextClamp>{discount.name}</TextClamp>
                    </DiscountName>
                  </DiscountContainer>
                ))
              )}
            </DiscountsContainer>
          )}
        </>
      )}

      <RestaurantContainer
        hasOffer={isSlide || !!deal}
        direction={direction}
        isSlide={isSlide}
      >
        <RestaurantLogo
          role="img"
          aria-label={`${outlet.displayName} logo`}
          imageUrl={imageJitURL(
            outlet.outletLogoOverride || outlet.restaurant.image,
            {
              resize: {
                width: 88,
                height: 88,
                fit: 'cover',
              },
            }
          )}
          isSlide={isSlide}
        >
          {outletWithBasketItems && basketItemCount && (
            <MenuItemCounter isOutletCard={true}>
              <MenuItemCounterText>{basketItemCount}</MenuItemCounterText>
            </MenuItemCounter>
          )}
        </RestaurantLogo>

        {showCoverImage && (
          <CoverImage
            title={`${outlet.displayName} cover image`}
            role="img"
            aria-label={`${outlet.displayName} cover image`}
            imageUrl={imageJitURL(outlet.coverImage, {
              resize: {
                width: 300,
                height: 176,
                fit: 'cover',
              },
            })}
          />
        )}

        <OutletDetailsContainer isSlide={isSlide}>
          <InfoContainer>
            <OutletName>
              <TextClamp>{outlet.displayName}</TextClamp>
            </OutletName>
            {outlet.outletLoyaltyCards.length > 0 ? (
              <LoyaltyCardStampAndIconContainer>
                <ImageContainer imageCount={outlet.outletLoyaltyCards.length}>
                  {outlet.outletLoyaltyCards.map((loyaltyCard, index) => (
                    <LoyaltyCardImage
                      key={loyaltyCard.id}
                      index={index}
                      imageCount={outlet.outletLoyaltyCards.length}
                      role="img"
                      aria-label={`${outlet.displayName} loyalty card`}
                      imageUrl={imageJitURL(loyaltyCard.loyaltyCardIcon, {
                        resize: {
                          width: 168,
                          height: 168,
                          fit: 'cover',
                        },
                      })}
                    />
                  ))}
                </ImageContainer>
                <ScallopedMaskSVG scale={0.42} />

                {loyaltyCardsWithStamps.length === 1 &&
                  outlet.outletLoyaltyCards.length === 1 && (
                    <LoyaltyCardStampProgressMeter
                      loyaltyCard={outlet.outletLoyaltyCards[0]}
                      isOutletList={true}
                    />
                  )}

                <LoyaltyCardTextContainer
                  textColor={
                    outlet.outletLoyaltyCards.length > 0
                      ? outlet.outletLoyaltyCards[
                          outlet.outletLoyaltyCards.length - 1
                        ]?.loyaltyCardColor || 'black'
                      : 'black'
                  }
                >
                  {loyaltyCardText()}
                </LoyaltyCardTextContainer>
              </LoyaltyCardStampAndIconContainer>
            ) : (
              <CusinesContainer isSlide={isSlide}>
                {outlet.outletCuisines
                  .slice(0, 3)
                  .map((cuisine: { name: string }, index: number) => {
                    return (
                      <CuisineTag key={index}>
                        {index !== 0 ? <CuisineDot /> : null}

                        <Cuisine>{cuisine.name}</Cuisine>
                      </CuisineTag>
                    )
                  })}
              </CusinesContainer>
            )}
          </InfoContainer>
          <OutletOpeningContainer>
            <OpenStatusContainer>
              <MinimumOrderContainer>
                {effectiveFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.DELIVERY &&
                  !!outlet.deliveryMinimumOrderValue && (
                    <>
                      {formatCurrency(outlet.deliveryMinimumOrderValue)}{' '}
                      {t('minimum_order', { ns: 'outletCard' })}
                    </>
                  )}
                {effectiveFulfilmentMethod ===
                  NarrowFulfilmentMethodInputType.COLLECTION &&
                  !!outlet.collectionMinimumOrderValue && (
                    <>
                      {formatCurrency(outlet.collectionMinimumOrderValue)}{' '}
                      {t('minimum_order', { ns: 'outletCard' })}
                    </>
                  )}
              </MinimumOrderContainer>
              <OutletStatusTranslationText
                statusText={outlet.statusText}
                fulfilmentRange={outlet.fulfilmentRange}
                isOutletPage={false}
              />
            </OpenStatusContainer>
            <LongSquareButton
              status={outlet.statusText.orderButtonTranslation}
              content={t(outlet.statusText.orderButtonTranslation, {
                ns: 'outlet',
              })}
            />
          </OutletOpeningContainer>
        </OutletDetailsContainer>
      </RestaurantContainer>
    </LinkToOutletContainer>
  )
}
