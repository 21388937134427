import React from 'react'

import { DateifyOutlet } from '@src/utils/fulfilmentTimes/parsers'

import { SingleOutlet } from '../SingleOutlet/SingleOutlet'
import { SingleOutlet as SingleOutletType } from '../SingleOutlet/types'

export const OutletSlide: React.VFC<{
  outlet: DateifyOutlet<
    Pick<
      SingleOutletType,
      | 'id'
      | 'displayName'
      | 'ASAPDeliveryDuration'
      | 'prepTime'
      | 'restaurant'
      | 'availableFulfilmentInputMethods'
      | 'deliveryMinimumOrderValue'
      | 'collectionMinimumOrderValue'
      | 'distanceFromUserKM'
      | 'coverImage'
      | 'statusText'
      | 'isOpen'
      | 'outletLogoOverride'
      | 'isOnline'
      | 'nextOpenDate'
      | 'openingTimesArray'
      | 'collectionPreorderTimes'
      | 'fulfilmentRange'
      | 'outletAllDiscounts'
      | 'specialOfferFlags'
      | 'outletCuisines'
      | 'outletLoyaltyCards'
      | 'availableFulfilments'
    >
  > | null
  showFeaturedFlags?: boolean
  showDiscountLabels?: boolean
  dataTestId?: string
}> = ({ outlet, showFeaturedFlags, showDiscountLabels, dataTestId }) => {
  if (!outlet) return null

  return (
    <SingleOutlet
      outlet={outlet}
      showFeaturedFlags={showFeaturedFlags}
      showDiscountLabels={showDiscountLabels}
      isSlide
      dataTestId={dataTestId}
    />
  )
}
